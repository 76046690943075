@import 'theme';

#logreg-forms{
    margin:3vh auto;
    padding: 0em 1.5em;
    background-color: var(--color-surface-secondary);
    box-shadow: 0 7px 7px rgba(0, 0, 0, 0.12), 0 12px 40px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    border-radius: var(--border-radius-lg);

    h1 {
        padding: 24px;
    }

    form {
        width: 100%;
        padding: 1em 0em 3.5em 0em;
        margin: auto;
    }
    .form-control {
        position: relative;
        box-sizing: border-box;
        height: auto;
        padding: 10px;
        font-size: 16px;

        background-color: var(--color-input-background);
        color: var(--color-text-primary);

        &[readonly] {
            background-color: rgb(255 255 255 / .4);
            border: 1px solid var(--color-surface-secondary);
            color: white;
        }
        border: 1px solid var(--color-surface-secondary);
    }
    .form-control:focus {
        z-index: 2;
        box-shadow: none;
        border-color: #ced4da;
    }
    .form-signin input[type="email"] {
        margin-bottom: -1px;
    }

    .social-login{
        margin-top: 20px;
        display: flex;
        justify-content: center;
        gap: 20px;
    }
    .social-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        color: white;
        font-size: 18px;
        transition: all 0.3s ease;

        &:hover {
            transform: scale(1.1);
        }
    }

    a{
        color:var(--color-text-primary)
    }

    #logreg-form .lines{
        width:200px;
        border:1px solid red;
    }


    button[type="submit"]{ margin: 1.8em 0em 0.1em 0em; }

    .facebook-btn{
        background-color:#3976EA;

        &:hover {
          background-color:#2b66d3;
        }
    }

    .google-btn{
        background-color: #DB4A39;

        &:hover {
          background-color: #ce3c2c;
        }
    }

    .apple-btn{
        background-color: black;
    }

    .submit{
        background: -webkit-linear-gradient(0deg,  #2dfbff 0%, #3c96ff 100%);
        border-radius: 25px;
        color: #fff;
    }

    .divider {
        display: flex;
        align-items: center;
        text-align: center;
        margin: 20px 0;
    }

    .divider::before,
    .divider::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid var(--color-divider);
    }
    .divider span {
      padding: 0 10px;
      color: var(--color-text-primary);
      font-size: 14px;
    }
}
  /* Mobile */

  @media screen and (max-width:500px){
      #logreg-forms{
        padding: 0em 0.75em;

       .social-login{
          flex-direction: row;
          justify-content: center;
      }
      .social-btn{
          font-size: 16px;
      }
      .social-btn span{
          display: none;
      }
       .facebook-btn:after,
       .google-btn:after {
          content: none;
      }
    }
  }
